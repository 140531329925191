angular.module("aerosApp")
    .factory('showLicenseUsersModal', ["$uibModal", function ($uibModal) {
        function open(license, me) {
            var modal = $uibModal.open({
                size: "md",
                resolve: {
                    license: function () {return license;},
                    me: function () {return me;},
                    allRoles: ['allRoles', function(allRoles) {
                        return allRoles.getAllRoles();
                    }]
            },
                templateUrl: "/static/templates/project/showLicenseUsersModal.html",
                controller: "ShowLicenseUsersModalCtrl"
            });
            return modal.result;
        }

        return {
            open: open
        };
    }])
    .controller("ShowLicenseUsersModalCtrl", ["$scope", "$uibModalInstance", "aerosApi", "license", "Notification","allRoles", "me", function ($scope, $uibModalInstance, aerosApi, license, Notification, allRoles, me) {
        $scope.roleNameById = {};
        $scope.roleRankById = {};
        $scope.license = license;
        $scope.users = license.assignedToUsers;
        $scope.isUnassigned = false;


        (function initRoles(types){
            _.each(types['orgRoles'], function (role) {
                $scope.roleNameById[role.id] = role.name;
                $scope.roleRankById[role.id] = role.rank;
           });
            $scope.bestRankedId = function (roles) {
                var bestRank = 999;
                var bestRankedId = "";
                _.each(roles, function (role) {
                    var roleLower = role.role.toLowerCase();
                    var roleRank = $scope.roleRankById[role.role.toLowerCase()];
                    if (roleRank < bestRank) {
                        bestRank = roleRank;
                        bestRankedId = roleLower;
                    }
                });
                return bestRankedId;
            };
            $scope.availableRoles = _.filter(types['orgRoles'], function (role) {
                return _.includes(role.id);
            });
            $scope.basicRoles = _.filter(types['orgRoles'], function (role) {
                return role.id === 'user';
            });
        })(allRoles);


        if(license.assignedToAdmin !== 'undefined') {
            $scope.users.push(license.assignedToAdmin);
        }
        $scope.me = me;
        $scope.unAssignUser = function (user) {
            aerosApi.removeUserFromLicense($scope.license.organizationId, user.licenseKey, user.username)
                .success(function () {
                    aerosApi.loadOrganization($scope.license.organizationId);
                    $scope.isUnassigned = true;
                    Notification.success("User (" + user.username + ") successfully unassigned from licensekey (" + user.licenseKey + ")");
                })
                .error(function (err) {
                    Notification.error("User not unassigned. " + err.message);
                });
        };
    }]);
